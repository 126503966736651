import axios from "axios";

const warehouseAPI = `${process.env.VUE_APP_WAREHOUSE_API_URL}/finance/`;
const AUTH_TOKEN = process.env.VUE_APP_WAREHOUSE_API_AUTH_TOKEN;
// let warehouseAPI = `http://node_backend.test/api/v1/finance/`
export default {
  async getFinanceData(){
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let res = await axios.get(`${warehouseAPI}finance-data?timestamp=${new Date().getTime()}`);    
    return res.data;
  },
  async getWeeklyFinanceData(invoiceNumber, manHours){
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let res = await axios.get(`${warehouseAPI}weekly-finance-data?invoiceNumber=${invoiceNumber}&manHours=${manHours}`);    
    return res.data;
  },
  async submitWeeklyFinanceData(formData){
    if (!formData) throw Error("Error getting form data");
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
  
    let url = `${warehouseAPI}weekly-finance-data`;
    let res = await axios.post(url, formData);
    return res.data;
  },
  async getFinanceComments(weekEnding){
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let res = await axios.get(`${warehouseAPI}finance-comment?weekEnding=${weekEnding}&timestamp=${new Date().getTime()}`);    
    return res.data;
  },
  async saveFinanceComment(formData){
    if (!formData) throw Error("Error getting form data");
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}finance-comment`;
    let res = await axios.post(url, formData);
    return res.data;
  },
  async deleteFinanceComment(weekEnding, reportReference){
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}finance-comment?weekEnding=${weekEnding}&reportReference=${reportReference}`;
    let res = await axios.delete(url);
    return res.data;
  },
  async insertCarrierData(formData){
    if (!formData) throw Error("Error getting form data");
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}carrier-data`;
    let res = await axios.post(url, formData);
    return res.data;
  },
  async compareCarrierData(invoiceNumber){
    axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
    let url = `${warehouseAPI}compare-carrier-data?invoiceNumber=${invoiceNumber}`;
    let res = await axios.get(url);
    return res.data;
  },
}