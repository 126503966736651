<template>
  <div class="adhocCharges">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card class="mb-4 pa-4" :loading="loading">
       
        <v-row>
          <v-col cols="12">
             <v-card-title>Upload Carrier Data</v-card-title>
          </v-col>
          <v-col cols="3">
            <v-file-input
              class="mb-2"
              show-size
              counter
              multiple
              dense
              outlined
              small-chips
              prepend-icon=""
              prepend-inner-icon="attach_file"
              label="File"
              ref="fileupload"
              @change="handleFileUpload"
            ></v-file-input>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="filePassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              label="File Password"
              hint="Defaulted to hermes password"
              counter
              @click:append="showPassword = !showPassword"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="carriers"
              v-model="selectedCarrier"
              item-text="name"
              item-value="name"
              label="Carrier"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-btn
              class="mb-2"
              block
              :loading="loading"
              :disabled="loading || !selectedCarrier || !carrierFile"
              color="primary"
              @click="readUploadedFile()"
              >Read File
            </v-btn>
          </v-col>
          <v-col cols="4">
          </v-col>
          <v-col cols="4" v-if="formattedData && formattedData.length > 0">
             <v-btn
              class="mb-2"
              block
              :loading="loading"
              :disabled="loading"
              outlined
              color="primary"
              @click="uploadCarrierData()"
              >Upload Carrier Data
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card class="pa-4" v-if="formattedData.length > 0" outlined>
              <v-card-title>
                File Data
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="formattedDataHeaders"
                :items="formattedData"
                :items-per-page="10"
                :search="search"
              >
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

       <v-card class="mb-4 px-4 pt-4 pb-8" :loading="loading">
       <v-row>
         <v-col cols="12">
           <v-card-title>
             Compare Committed - Carrier Data
           </v-card-title>
         </v-col>
         <v-col cols="4">
            <v-select
                  v-model="selectedInvoice"
                  :items="invoiceNumbers"
                  item-text="invoiceNumbers"
                  label="Select Invoice Number"
                  outlined
                  dense
                ></v-select>
         </v-col>
         <v-col cols="4">
            <v-btn
              block
              :loading="loading"
              :disabled="loading || !selectedInvoice"
              color="primary"
              @click="compareCarrierData()"
              >Compare Data
            </v-btn>
         </v-col>
        <v-col cols="4">
        </v-col>
        <v-col cols="4" v-if="comparedData && comparedData.length > 0">
           <ExportButtons
                  :headers="comparedDataHeaders"
                  :data="comparedData"
                  :title="'ComparedData' + selectedInvoice "
                />
        </v-col>
         <v-col cols="12" v-if="comparedData && comparedData.length > 0">
             <v-data-table
                :headers="comparedDataHeaders"
                :items="comparedData"
                :items-per-page="10"
                :search="search"
              >
              </v-data-table>
         </v-col>
       </v-row>
      </v-card>
      

      <!-- <v-card :loading="loading">
        <v-form v-model="valid" @submit.prevent>
          <v-container>
            <v-row>
              <v-col md="3" v-if="queryData">
                <v-select
                  :items="weeksCommencingSearch"
                  v-model="weekCommencing"
                  item-text="week_commencing"
                  item-value="week_commencing"
                  label="Week Commencing"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col md="3" v-if="!queryData">
                <v-select
                  :items="carriers"
                  v-model="selectedCarrier"
                  item-text="name"
                  item-value="name"
                  label="Carrier"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <date-picker
                v-if="!queryData"
                @clicked="setDateRange"
                v-model="datesPicked"
                :colWidth="3"
              ></date-picker>
              <v-col md="3" v-if="!queryData">
                <v-text-field
                  label="Invoice Number"
                  outlined
                  dense
                  type="text"
                  name="invoiceNumber"
                  id="invoiceNumber"
                  v-model="invoiceNumber"
                ></v-text-field>
              </v-col>
              <v-col md="3" v-if="!queryData">
                <v-text-field
                  label="Invoice Value"
                  outlined
                  dense
                  type="number"
                  name="invoiceValue"
                  id="invoiceValue"
                  prefix="£"
                  v-model="invoiceValue"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn
                  v-if="queryData"
                  class="mr-1 ml-1"
                  :loading="loading"
                  :disabled="weekCommencing == null"
                  color="primary"
                  @click="getReconciledData()"
                  >Run Query</v-btn
                >
                <v-btn
                  v-if="!queryData"
                  class="mr-1 ml-1"
                  :loading="loading"
                  :disabled="
                    selectedCarrier == null ||
                      dateTo == null ||
                      dateFrom == null ||
                      invoiceValue == null ||
                      invoiceNumber == null
                  "
                  color="primary"
                  @click="reconcileInvoice()"
                  >Reconcile</v-btn
                >
                <v-btn
                  v-if="queryData"
                  class="mr-1 ml-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="queryData = !queryData"
                  >Reconcile Invoices</v-btn
                >
                <v-btn
                  v-if="!queryData"
                  class="mr-1 ml-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="queryData = !queryData"
                  >Query Data</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-card class="mt-4" v-if="distributionRevenueItems.length > 0">
        <v-card-title>
          Company Billing Data
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="distributionRevenueHeaders"
          :items="distributionRevenueItems"
          :items-per-page="10"
          :search="search"
          id="data-table"
        >
          <template v-slot:item.revenue="{ item }">{{
            renderCurrency(item.revenue)
          }}</template>
          <template v-slot:item.invoiceValue="{ item }">{{
            renderCurrency(item.invoiceValue)
          }}</template>
          <template v-slot:item.profitLoss="{ item }">
            <v-chip dark>{{ renderCurrency(item.profitLoss) }}</v-chip>
          </template>
          <template v-slot:item.margin="{ item }">{{ item.margin }}%</template>
          <template v-slot:item.costPerParcel="{ item }">{{
            renderCurrency(item.costPerParcel)
          }}</template>
          <template v-slot:item.revenuePerParcel="{ item }">{{
            renderCurrency(item.revenuePerParcel)
          }}</template>
          <template v-slot:item.profitPerParcel="{ item }"
            >{{ item.profitPerParcel }}%</template
          >
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
            <v-icon small @click="addInvoice(item)">add</v-icon>
          </template>
        </v-data-table>
      </v-card> -->
      <v-snackbar v-model="snackbar" :timeout="3000">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false"></v-btn>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
// import Carriers from "@/services/Carriers.js";
// import DatePicker from "../components/forms/DatePicker";
import XlsxPopulate from "xlsx-populate";
import FinanceService from "@/services/Finance.js";
import CommitedData from "@/services/CommitedData.js";
import ExportButtons from "@/components/ExportButtons";
export default {
  components: {
    ExportButtons,
  },
  data() {
    return {
      // Title
      title: "Carrier Margins Reconciliation",
      // Form
      valid: false,
      // Loading
      loading: false,
      // Snackbar
      snackbar: false,
      text: "",
      // Companies
      carriers: [
        "DHL",
        "DPD",
        "DPD Local",
        "Hermes",
        "Hermes ROTW",
        "Parcelforce",
        "Royal Mail",
      ],
      selectedCarrier: "Hermes",
      datesPicked: [],
      dateFrom: null,
      dateTo: null,
      invoiceValue: null,
      invoiceNumber: null,
      search: null,
      distributionRevenueItems: [],
      // Snap Table
      distributionRevenueHeaders: [
        { text: "Carrier", value: "carrier" },
        { text: "Invoice Number", value: "invoiceNumber" },
        { text: "Revenue", value: "revenue" },
        { text: "Orders", value: "orders" },
        { text: "Invoice Value", value: "invoiceValue" },
        { text: "Profit / Loss", value: "profitLoss" },
        { text: "Margin", value: "margin" },
        { text: "Cost (Parcel)", value: "costPerParcel" },
        { text: "Revenue (Parcel)", value: "revenuePerParcel" },
        { text: "Profit (Parcel)", value: "profitPerParcel" },
      ],
      weekCommencing: null,
      weeksCommencingSearch: [],
      queryData: true,

      carrierFile: null,
      filePassword: "rkykq5gg",
      showPassword: false,
      formattedData: [],
      formattedDataHeaders: [
        { text: "Carrier", value: "carrier" },
        { text: "Type", value: "type" },
        { text: "Company", value: "company" },
        { text: "Barcode", value: "barcode" },
        { text: "Post Code", value: "post_code" },
        { text: "Invoice Date", value: "invoice_date" },
        { text: "Carrier Year/Week", value: "carrier_year_week" },
        { text: "Client Year Week", value: "client_year_week" },
        { text: "Customer Reference", value: "customer_reference" },
        { text: "Weight", value: "weight" },
        { text: "Total Value", value: "total_value" },
      ],

      invoiceNumbers: [],
      selectedInvoice: null,

      comparedData: [],
      comparedDataHeaders: [
        { text: "Company Code", value: "company_code" },
        { text: "Reference", value: "reference" },
        { text: "Weight", value: "weight" },
        { text: "Post Code", value: "post_code" },
        { text: "Charge Date", value: "charge_date" },
        { text: "Charge Type", value: "charge_type" },
        { text: "Billed Out", value: "total_charge" },
        { text: "Margin", value: "margin" },
        { text: "Fixed Rate Active", value: "fixedRateActive" },
        { text: "Fixed Rate", value: "fixedRate" },
        { text: "Carrier Charge", value: "carrier_charge" },
        { text: "Carrier Charge Inc. Margin", value: "calculated_charge" },
        { text: "Difference", value: "difference" },
      ],
    };
  },
  methods: {
    compareCarrierData(){
      if(!this.selectedInvoice){
        return
      }
      this.loading = true
      this.comparedData = []
      FinanceService.compareCarrierData(this.selectedInvoice)
        .then((response) => {
          this.loading = false;
          if (response.success && response.success == 200) {
            this.snackbar = true;
            this.text = `Loaded data for invoice ${this.selectedInvoice}`
            this.comparedData = response.data;
          }
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.message}`;
          this.loading = false;
      
        });
    },
    async getInvoiceNumbers() {
      this.loading = true
      // Use the BillingSnap to call the getBillingData() method
      return CommitedData.getInvoiceNumbers()
        .then(
          ((invoiceNumbers) => {
            this.loading = false
            this.$set(this, "invoiceNumbers", invoiceNumbers);
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    resetCarrierData(){
      this.carrierFile = null
      this.showPassword = false
      this.formattedData = []
      this.$refs.fileupload.reset()
    },
    uploadCarrierData(){
      if(!this.formattedData){
        return
      }
      this.loading = true
      let formData = {
        carrierData: this.formattedData
      }
      FinanceService.insertCarrierData(formData)
        .then((response) => {
          this.loading = false;
          if (response.success && response.success == 200) {
            this.snackbar = true;
            this.text = response.data;
            this.resetCarrierData()
          }
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.response.data.message}`;
          this.loading = false;
      
        });
    },
    async handleFileUpload(ev) {
      this.carrierFile = ev[0];
    },
    readUploadedFile() {
      this.loading = true;
      if (!this.carrierFile || !this.selectedCarrier) {
        return;
      }
      this.formattedData = []
      let options = {};
      if (this.filePassword) {
        options = {
          password: this.filePassword,
        };
      }
      XlsxPopulate.fromDataAsync(this.carrierFile, options).then((workbook) => {
        // ...
        let sheets = workbook.sheets();
        let formattedData = [];
        // loop through worksheets
        for (let i = 1; i < 4; i++) {
          let sheet = sheets[i];
          // get 2D array of rows and cols
          let values = sheet.usedRange().value();
          //get different col indexes depending on which worksheet is being looped
          let colIndexes = this.getColIndexes(i);
          // loop through rows
          for (let j = 1; j < values.length; j++) {
            let row = values[j];
            let obj = {
              carrier: this.selectedCarrier,
              type: colIndexes.type,
              company: row[2],
              barcode: row[4],
              post_code: row[colIndexes.postCode] ?? "",
              invoice_date: this.convertExcelDate( row[colIndexes.invoiceDate]),
              carrier_year_week: row[colIndexes.yearWeekCarrier],
              client_year_week: row[colIndexes.yearWeek],
              customer_reference: row[colIndexes.customerReference] ?? "",
              weight: row[colIndexes.weight] ?? "",
              total_value: row[colIndexes.totalValue],
            };
            formattedData.push(obj);
          }
        }

        this.formattedData = formattedData;
        this.loading = false;
      });
    },
    convertExcelDate(date){
      // excel date is the number of days since 1/1/1900
      var baseDate = new Date('1900-01-01');
      baseDate.setDate(baseDate.getDate() + date - 2); 
      return baseDate.toISOString().substr(0,10)
    },
    getColIndexes(sheetIndex) {
      switch (sheetIndex) {
        case 1:
          return {
            type: "DeliveryVolume",
            invoiceDate: 5,
            yearWeekCarrier: 6,
            yearWeek: 7,
            customerReference: 8,
            weight: 10,
            postCode: 12,
            totalValue: 22,
          };
        case 2:
          return {
            type: "Returns",
            invoiceDate: 5,
            yearWeekCarrier: 6,
            yearWeek: 7,
            customerReference: -1,
            weight: 8,
            postCode: 10,
            totalValue: 13,
          };
        case 3:
          return {
            type: "ChargeableEvents",
            invoiceDate: 6,
            yearWeekCarrier: 7,
            yearWeek: 8,
            customerReference: 9,
            weight: -1,
            postCode: -1,
            totalValue: 16,
          };
      }
    },

  //   setDateRange(dates) {
  //     this.datesPicked = dates;
  //     this.dateFrom = this.datesPicked[0];
  //     this.dateTo = this.datesPicked[1];
  //   },
  //   async loadInitialData() {
  //     Carriers.getWeeksCommencing()
  //       .then((response) => {
  //         this.weeksCommencingSearch = response;
  //         this.loading = false;
  //         this.text = `Select a Carrier and enter the required details to reconcile an invoice.`;
  //         this.snackbar = true;
  //       })
  //       .catch((error) => {
  //         let message = error.response
  //           ? error.response.data.errorMessage[0]
  //           : error.message;
  //         this.snackbar = true;
  //         this.text = message;
  //         this.loading = false;
  //         return false;
  //       });
  //   },
  //   async reconcileInvoice() {
  //     this.loading = true;
  //     Carriers.getReconciledData(
  //       this.selectedCarrier,
  //       this.dateFrom,
  //       this.dateTo
  //     )
  //       .then(async (response) => {
  //         response[0]["invoiceNumber"] = this.invoiceNumber;
  //         response[0]["invoiceValue"] = this.invoiceValue;
  //         response[0]["profitLoss"] = response[0].revenue - this.invoiceValue;
  //         response[0]["margin"] = this.calculateMargin(
  //           response[0].revenue,
  //           this.invoiceValue
  //         );
  //         response[0]["costPerParcel"] = this.calculateCostPerParcel(
  //           this.invoiceValue,
  //           response[0].orders
  //         );
  //         response[0]["revenuePerParcel"] = this.calculateRevenuePerParcel(
  //           response[0].revenue,
  //           response[0].orders
  //         );
  //         response[0]["profitPerParcel"] = this.calculateProfitPerParcel(
  //           response[0].revenue,
  //           this.invoiceValue,
  //           response[0].orders
  //         );
  //         response[0]["weekCommencing"] = this.dateFrom;
  //         let checkForCarrier = this.distributionRevenueItems.findIndex(
  //           (item) => item.carrier === this.selectedCarrier
  //         );
  //         if (checkForCarrier !== -1) {
  //           let combine = await this.combineResults(
  //             checkForCarrier,
  //             response[0]
  //           );
  //           if (combine) {
  //             this.storeDistributionRevenueData(response[0]);
  //           }
  //         } else {
  //           this.distributionRevenueItems.push(response[0]);
  //           this.storeDistributionRevenueData(response[0]);
  //         }
  //         this.snackbar = true;
  //         this.text = `Loaded the value successfully.`;
  //         this.loading = false;
  //       })
  //       .catch((error) => {
  //         let message = error.response
  //           ? error.response.data.errorMessage[0]
  //           : error.message;
  //         this.snackbar = true;
  //         this.text = message;
  //         this.loading = false;
  //         return false;
  //       });
  //   },
  //   async storeDistributionRevenueData(revenueData) {
  //     this.loading = true;
  //     Carriers.storeDistributionRevenueData(revenueData)
  //       .then((response) => {
  //         this.snackbar = true;
  //         this.text = `Succesffuly stored reconciliation data for ${response[0].invoice_number}.`;
  //         this.loading = false;
  //       })
  //       .catch((error) => {
  //         this.snackbar = true;
  //         this.text = `${error.response.data.errorMessage[0]} - Adding the already stored invoice to the table.`;
  //         this.distributionRevenueItems.pop();
  //         if (error.response.data.data.length > 0) {
  //           let returnedData = this.formatDatabaseData(
  //             error.response.data.data[0]
  //           );
  //           this.distributionRevenueItems.push(returnedData);
  //         }
  //         this.loading = false;
  //         return false;
  //       });
  //   },
  //   async getReconciledData() {
  //     Carriers.getReconciledData(this.weekCommencing)
  //       .then((response) => {
  //         response.forEach((responseItem) => {
  //           let formatted = this.formatDatabaseData(responseItem);
  //           let checkForCarrier = this.distributionRevenueItems.findIndex(
  //             (item) => item.carrier === responseItem.carrier
  //           );
  //           if (checkForCarrier !== -1) {
  //             this.combineResults(checkForCarrier, formatted);
  //           } else {
  //             this.distributionRevenueItems.push(formatted);
  //           }
  //         });
  //       })
  //       .catch((error) => {
  //         let message = error.response
  //           ? error.response.data.errorMessage[0]
  //           : error.message;
  //         this.snackbar = true;
  //         this.text = message;
  //         this.loading = false;
  //         return false;
  //       });
  //   },
  //   calculateMargin(revenue, invoice) {
  //     let percentage = 0;
  //     let difference = revenue - invoice;
  //     percentage = (difference / invoice) * 100;
  //     return percentage.toFixed(2);
  //   },
  //   calculateCostPerParcel(invoice, orders) {
  //     let costPerParcel = invoice / orders;
  //     return costPerParcel.toFixed(2);
  //   },
  //   calculateRevenuePerParcel(revenue, orders) {
  //     let revenuePerParcel = revenue / orders;
  //     return revenuePerParcel.toFixed(2);
  //   },
  //   calculateProfitPerParcel(revenue, cost, orders) {
  //     let profitPerParcel = (revenue - cost) / orders;
  //     return profitPerParcel.toFixed(2);
  //   },
  //   renderCurrency(value) {
  //     if (value === null) {
  //       value = 0.0;
  //     }
  //     return `£${parseFloat(value).toFixed(2)}`;
  //   },
  //   combineResults(itemIndex, invoiceToAdd) {
  //     if (
  //       this.distributionRevenueItems[itemIndex].invoiceNumber.indexOf(
  //         invoiceToAdd.invoiceNumber
  //       ) !== -1
  //     )
  //       throw Error("Invoice number already exists in Database.");
  //     this.distributionRevenueItems[
  //       itemIndex
  //     ].invoiceNumber += ` - ${invoiceToAdd.invoiceNumber}`;
  //     this.distributionRevenueItems[itemIndex].invoiceValue = (
  //       parseFloat(this.distributionRevenueItems[itemIndex].invoiceValue) +
  //       parseFloat(invoiceToAdd.invoiceValue)
  //     ).toFixed(2);
  //     this.distributionRevenueItems[itemIndex].profitLoss =
  //       this.distributionRevenueItems[itemIndex].revenue -
  //       this.distributionRevenueItems[itemIndex].invoiceValue;
  //     this.distributionRevenueItems[itemIndex].margin = this.calculateMargin(
  //       this.distributionRevenueItems[itemIndex].revenue,
  //       this.distributionRevenueItems[itemIndex].invoiceValue
  //     );
  //     this.distributionRevenueItems[
  //       itemIndex
  //     ].costPerParcel = this.calculateCostPerParcel(
  //       this.distributionRevenueItems[itemIndex].invoiceValue,
  //       this.distributionRevenueItems[itemIndex].orders
  //     );
  //     this.distributionRevenueItems[
  //       itemIndex
  //     ].revenuePerParcel = this.calculateRevenuePerParcel(
  //       this.distributionRevenueItems[itemIndex].revenue,
  //       this.distributionRevenueItems[itemIndex].orders
  //     );
  //     this.distributionRevenueItems[
  //       itemIndex
  //     ].profitPerParcel = this.calculateProfitPerParcel(
  //       this.distributionRevenueItems[itemIndex].revenue,
  //       this.distributionRevenueItems[itemIndex].invoiceValue,
  //       this.distributionRevenueItems[itemIndex].orders
  //     );
  //     return true;
  //   },
  //   formatDatabaseData(returnedData) {
  //     return {
  //       carrier: returnedData.carrier,
  //       invoiceNumber: returnedData.invoice_number,
  //       revenue: returnedData.revenue,
  //       orders: returnedData.orders,
  //       invoiceValue: returnedData.invoice_value,
  //       profitLoss: returnedData.profit_loss,
  //       margin: returnedData.margin,
  //       costPerParcel: returnedData.cost_per_parcel,
  //       revenuePerParcel: returnedData.revenue_per_parcel,
  //       profitPerParcel: returnedData.profit_per_parcel,
  //     };
  //   },
  },
  created() {
    this.getInvoiceNumbers();
  },
};
</script>

<style>
form {
  padding: 1em;
  padding-bottom: 0em;
}
.v-card {
  padding-bottom: 1em;
}
.v-card p {
  padding-left: 1em;
}
</style>
